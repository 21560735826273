let header = document.querySelector('.header')

if(header) {
    let burgerMenu = document.querySelector('.header-mobile__btn')
    burgerMenu.addEventListener('click', () => {
        burgerMenu.classList.toggle('active')
        header.classList.toggle('active')
        document.querySelector('.header-mobile__content').classList.toggle('active')
        document.querySelector('body').classList.toggle('hidden')
    })


    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    }
}

let footer = document.querySelector('.footer');
if (footer) {
    let openPopup = document.querySelector('.open-popup');
    let closePopup = document.querySelectorAll('.popup-close');
    let ratingPopup = document.querySelector('.rating-popup');

    if (openPopup) {
        openPopup.addEventListener('click', () => {
            ratingPopup.classList.add('active');
        })
    }

    if (closePopup) {
        closePopup.forEach((item) => {
            item.addEventListener('click', () => {
                ratingPopup.classList.remove('active');
            })
        })
    }
}


let customSelect = document.querySelector(".select");
if (customSelect) {
    document.querySelectorAll('.select').forEach(select => {
        let selectCurrent = select.querySelector('.select__current');
        if (selectCurrent) {
            let selectList = select.querySelector('.select__list');
            let selectInput = select.querySelector('.select__input');
            let selectItem = select.querySelectorAll('.select__item');

            selectCurrent.addEventListener('click', () => {
                selectList.classList.toggle('show')
            })

            selectItem.forEach(item => {

                item.addEventListener('click', () => {

                    let itemValue = item.getAttribute('data-value')

                    let itemText = item.textContent
                    let itemImgSrc = item.querySelector('img').src

                    selectInput.value = itemValue
                    selectCurrent.textContent = itemText

                    let img = document.createElement('img');
                    img.src = itemImgSrc.toString()
                    selectCurrent.appendChild(img);

                    $('.select__list').next().find('p').hide();

                    selectListHide()
                })
            })

            let selectListHide = () => {
                selectList.classList.remove('show');
                $('.error').hide();
            }

            document.addEventListener('mouseup', (e) => {
                if (!select.contains(e.target)) selectListHide()
            })
        }
    })
}
